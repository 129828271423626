import { createWebHistory, createRouter } from "vue-router";
import { Auth } from 'aws-amplify'
import AuthComponent from '../components/Auth'

import domo_poc from "../components/domo_poc.vue";
import AWS_Quick_Sight_V2 from "../components/AWS_Quick_Sight_V2";
import GoogleLooker from "../components/GoogleLooker";
import Tools from "../components/Tools";

const routes = [
  {
    path: "/tools",
    name: "tools",
    component: Tools,
    meta: { requiresAuth: true },
  },
  {
    path: "/domo_poc",
    name: "domo_poc",
    component: domo_poc,
    meta: { requiresAuth: true },
  },
  {
    path: "/AWS_Quick_Sight_V2/:dash_board_id",
    name: "AWS_Quick_Sight",
    component: AWS_Quick_Sight_V2,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/AWS_Quick_Sight_V2/:dash_board_id",
    name: "AWS_Quick_Sight_V2",
    component: AWS_Quick_Sight_V2,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/GoogleLooker",
    name: "GoogleLooker",
    component: GoogleLooker,
    meta: { requiresAuth: true },
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthComponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then(() => {
      next()
    }).catch(() => {
      next({
        path: '/auth'
      });
    });
  } else {
    next()
  }
})

export default router;