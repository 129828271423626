<template>
  <div id="app" class="app-container">
    <div class="header"><strong>This tool is used to generate a biography for the given channel</strong></div>
    
    <div class="options">
      <label class="checkbox-label">
        <input type="checkbox" v-model="includeTranscripts">
        Include video transcript
      </label>
    </div>

    <div class="input-group">
      <input type="text" v-model="ucid" placeholder="Enter UCID" class="ucid-input" />
      <button @click="checkInputAndSearch()" class="generate-btn">Generate Bio</button>
    </div>

    <div class="output">
      <div v-if="loading">
        <img src="./spinner.gif" alt="Loading..." class="spinner">
      </div>
      <div v-else>
        <textarea v-model="displayText" class="text-output"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import _ from 'lodash'; // Assuming lodash is added for debouncing
import "@/assets/css/bio_styles.css";

export default {
  name: 'GenerateBio',
  data() {
    return {
      loading: false,
      ucid: '',
      displayText: '',
      includeTranscripts: false
    };
  },
  created() {
    // this.debouncedFetchResults = _.debounce(this.fetchResults, 500); // 500 ms debounce
    // DEV
    // this.api = "/api/idea/generate_bio"

    const baseURL = 'https://spotter-data-api.de.dev.internal.spotter.la/api/idea';
    this.api = `${baseURL}/generate_bio`;
  },
  methods: {
    checkInputAndSearch() {
      if (!this.ucid.trim()) {
        alert('Please enter the ucid.');
      } else {
        this.fetchResults();
      }
    },
    async fetchResults() {
      this.loading = true;
      const params = new URLSearchParams({
        ucid: this.ucid,
        includeTranscripts: this.includeTranscripts
      }).toString();

      try {
        const response = await axios.get(`${this.api}?${params}`);
        this.displayText = response.data.results;
      } catch (error) {
        console.error('Error fetching results:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.columns {
  display: flex;
}

.column {
  flex: 1;
  padding: 10px;
}
</style>
