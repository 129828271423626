<template>
  <div>
    <h1>Creator News</h1>
    <ul>
      <li v-for="article in articles" :key="article.id">
        <h3>{{ article.author }}</h3>
        <p>{{ article.description }}</p>
        <p>{{ article.content }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ws: null, // WebSocket instance
      messages: [], // Store messages received from the server
      articles: [] // Store articles received from the server
    };
  },
  created() {
    this.connect();
  },
  methods: {
    connect() {
      // Initialize WebSocket connection
      this.ws = new WebSocket('ws://localhost:8000/ws/news/');

      this.ws.onopen = (event) => {
        console.log("WebSocket connection opened:", event);
      };

      this.ws.onmessage = (event) => {
        // Handle incoming messages
        const message = JSON.parse(event.data);
        console.log('Parsed message:', message); // Check parsed message structure
        this.messages.push(message);
        // Assuming data has a 'news' object which contains an 'articles' array
        if (message.news && message.news.articles) {
          this.articles = message.news.articles;
        }
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      this.ws.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
        // Optionally attempt to reconnect or handle the close gracefully
        // etTimeout(() => this.connect(), 5000); // Reconnect after 5 seconds
      };
    }
  },
  beforeUnmount() {
    // Close WebSocket connection when component is destroyed
    if (this.ws) {
      this.ws.close();
    }
  }
};
</script>
