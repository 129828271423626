<template>
  <div id="cms-data-pull" style="font-family: Arial, sans-serif; margin: 0 auto; width: 80%;">
    <div style="margin-bottom: 20px;">
      <strong>Notes:</strong>
      <ul style="list-style-type: disc; margin-left: 20px;">
        <li style="text-align: left;">If you provide only the CMS name, then all channels under this CMS will be pulled.</li>
        <li style="text-align: left;">If you provide only a list of channels, then the specified channels will be retrieved across all CMSs to which these channels belong.</li>
        <li style="text-align: left;">If you provide both the CMS name and a list of channels, then only the specified channels under the given CMS will be retrieved.</li>
        <li style="text-align: left;">Backfill will pull the complete historical data.</li>
        <li style="text-align: left;">Incremental retrieval will pull the last two months of data for the video_daily and traffic tables. Other tables will continue to pull the complete historical data.</li>
        <li style="text-align: left;">I set the concurrency to 1 to ensure that one job completes before another is submitted. Notifications of job start and completion are sent to pricing-analytics-tech.</li>
        <li style="text-align: left;">The current version does not support the initial pull of a new CMS. Please contact Dihan or Kunal to initiate the pull of a new CMS.</li>
      </ul>
    </div>
    <div style="margin-bottom: 20px;">
      <label for="cmsDropdown">Select CMS:</label>
      <select v-model="selectedCms" id="cmsDropdown" style="margin-left: 10px;">
        <option :key="none" :value="none">None</option>
        <option v-for="cms in all_cms" :key="cms" :value="cms">{{ cms }}</option>
      </select>
    </div>
    <div style="margin-bottom: 20px;">
      <textarea v-model="channelIds"
        placeholder="Enter ucids in comma separated string" rows="10" cols="50"></textarea>
    </div>
    <div><strong>Please select the action:</strong></div>
    <div style="margin-top: 10px;">
      <div v-for="action in actions" :key="action" style="margin-bottom: 5px;">
        <input type="radio" v-model="selectedAction" name="selectedAction"
          :value="action" />
        {{ action }}
      </div>
    </div>
    <button @click="submit()">Submit</button>
    <br />
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { API } from "aws-amplify";

export default {
  name: "cms-data-pull",

  data() {
    return {
      message: "",
      actions: [],
      all_cms: []
    };
  },

  created() {
    this.actions = ["backfill", "incremental"];
    this.channelIds = "";
    this.selectedCms = null;
    // this.cmsName = "";
    this.selectedAction = "incremental";
    this.cms()
  },

  methods: {
    async cms() {
      console.log("Get all cms");

      const apiName = "cmsdatapull";
      const path = "/cms";
      const myInit = {
        headers: {
          "Content-Type": "text/plain",
          accept: "*/*",
        },
        response: true,
        queryStringParameters: {
          path: "cms",
        },
      };

      await API.get(apiName, path, myInit)
        .then((response) => {
          var _data = response.data;
          for (var i = 0; i < _data.length; i++) {
            var cms_name = _data[i];
            this.all_cms.push(cms_name);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },

    async submit() {
      if (confirm("Are you sure to pull the cms data?")) {
        console.log("Selected action is:" + this.selectedAction);
        console.log("Selected CMS is:" + this.selectedCms);
        // console.log("Entered CMS is:" + this.cmsName);
        console.log("Entered UCID is:" + this.channelIds);

        var ucids = "";

        if (this.channelIds) {
          var array = this.channelIds.split(/,|\s|\n/);
          array = array.filter(function (line) {
            return line.length == 24 && line.startsWith("UC");
          });
          ucids = array.join(",").trim();
        }

        const apiName = "cmsdatapull";
        const path = "/items";
        const myInit = {
          headers: {
            "Content-Type": "text/plain",
            accept: "*/*",
          },
          response: true,
          queryStringParameters: {
            path: "items",
            action: this.selectedAction,
            selectedCms: this.selectedCms,
            // cmsName: this.cmsName.trim(),
            ucids: ucids
          },
        };

        await API.get(apiName, path, myInit)
          .then((response) => {
            console.log(response);
            this.message = response.data;
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            this.message = error.response.data;
          });
      }
    },
  },
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
</style>