<template>
  <div id="app">
    <input type="text" v-model="searchPhrase" placeholder="Enter search phrase" style="width: 25%;margin-right: 20px;" />
    <button @click="checkInputAndSearch()">Search</button>
  
    <div class="columns">
      <!-- Loop through columns -->
      <div class="column" v-for="(column, index) in columns" :key="index">
        <!-- <div class="input-row">
          <div class="input-group">
            <label for="viewsWeight-${index}">Views Weight:</label>
            <input type="number" v-model="column.viewsWeight" :id="'viewsWeight-' + index" placeholder="Views Weight" />
          </div>

          <div class="input-group">
            <label for="subscribersWeight-${index}">Subscribers Weight:</label>
            <input type="number" v-model="column.subscribersWeight" :id="'subscribersWeight-' + index" placeholder="Subscribers Weight" />
          </div>

          <div class="input-group">
            <label for="performanceWeight-${index}">Perf Index Weight:</label>
            <input type="number" v-model="column.performanceWeight" :id="'performanceWeight-' + index" placeholder="Perf Index Weight" />
          </div>

          <div class="input-group">
            <label for="uploadedWeight-${index}">Uploaded Weight:</label>
            <input type="number" v-model="column.uploadedWeight" :id="'uploadedWeight-' + index" placeholder="Uploaded Weight" />
          </div>
        </div> -->

        <div class="input-row">
          <div class="labels-row">
            <label for="viewsWeight-${index}">views wt</label>
            <label for="subscribersWeight-${index}">subs wt</label>
            <label for="performanceWeight-${index}">perf idx wt</label>
            <label for="uploadedWeight-${index}">uploaded wt</label>
          </div>
          <div class="inputs-row">
            <input type="number" v-model="column.viewsWeight" id="viewsWeight-${index}" placeholder="Views Weight" />
            <input type="number" v-model="column.subscribersWeight" id="subscribersWeight-${index}" placeholder="Subscribers Weight" />
            <input type="number" v-model="column.performanceWeight" id="performanceWeight-${index}" placeholder="Perf Index Weight" />
            <input type="number" v-model="column.uploadedWeight" id="uploadedWeight-${index}" placeholder="Uploaded Weight" />
          </div>
        </div>

        <!-- Display video information for each result -->
        <div class="video-info" v-for="(video, vIndex) in column.results" :key="`video-${index}-${vIndex}`">
          <div class="video-header">
            <h3>{{ video.title1 }} <span class="score">Score: {{ video.score }}</span></h3>
          </div>
          <div class="video-content">
            <img :src="video.thumb" alt="Video Thumbnail" class="video-thumb">
            <div class="video-details">
              <p>Uploaded: {{ video.uploaded }}</p>
              <p>Channel: {{ video.name }}</p>
              <p>Views: {{ video.views }}</p>
              <p>Subscribers: {{ video.subscribers }}</p>
              <p>Performance Index: {{ formatPerformanceIndex(video.performance_index) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import axios from 'axios';
import _ from 'lodash'; // Assuming lodash is added for debouncing
import "@/assets/css/styles.css";

export default {
  name: 'TitleSearch',
  data() {
    return {
      accessToken: null,
      refreshToken: null,
      searchPhrase: '',
      columns: [
        { viewsWeight: 0.5, subscribersWeight: 0.5, performanceWeight: 0.1, uploadedWeight: 2.0, results: [] },
        { viewsWeight: 1.0, subscribersWeight: 1.0, performanceWeight: 0.1, uploadedWeight: 2.0, results: [] },
        { viewsWeight: 2.0, subscribersWeight: 2.0, performanceWeight: 0.1, uploadedWeight: 2.0, results: [] },
      ],
    };
  },
  computed: {
    // Computed property that tracks viewsWeight and subscribersWeight values
    weightData() {
      return this.columns.map(column => ({
        viewsWeight: column.viewsWeight,
        subscribersWeight: column.subscribersWeight,
        performanceWeight: column.performanceWeight,
        uploadedWeight: column.uploadedWeight
      }));
    }
  },
  watch: {
    // Watch the computed property for changes
    weightData: {
      handler: function(newWeights, oldWeights) {
        if (!_.isEqual(newWeights, oldWeights)) {
          this.debouncedFetchResults();
        }
      },
      deep: true
    }
  },
  created() {
    this.debouncedFetchResults = _.debounce(this.fetchResults, 500); // 500 ms debounce
    
    // DEV
    // this.api = "/api/idea/channel_video/title_search_empirical_test";
    // this.bowser_api = "/api/auth0/api-key-login/"
    // SERVER
    const baseURL = 'https://spotter-data-api.de.dev.internal.spotter.la/api/idea/channel_video';
    this.api = `${baseURL}/title_search_empirical_test`;

    const bowser_baseURL = 'https://dev-bowser.spotter-dev.com';
    this.bowser_api = `${bowser_baseURL}/api/auth0/api-key-login/`;
    
  },
  methods: {
    formatPerformanceIndex(value) {
      if (value !== undefined && value !== null && !isNaN(value)) {
        return value.toFixed(3);
      }
      return ''; // Or some default/fallback value
    },

    checkInputAndSearch() {
      if (!this.searchPhrase.trim()) {
        alert('Please enter a search phrase.');
      } else {
        this.login();
        this.fetchResults();
      }
    },

    async login() {
      try {
        const apiName = "titlesearch";
        const path = "/titlesearch";
        const myInit = {
          headers: {
            "Content-Type": "text/plain",
            accept: "*/*",
          },
          response: true,
          queryStringParameters: {
            path: "titlesearch",
          },
        };

        const ts_response = await API.get(apiName, path, myInit);
        const apiKey = ts_response.data.apiKey;
        const apiSignature = ts_response.data.apiSignature;

        const response = await axios.post(`${this.bowser_api}`, {
          key: apiKey,
          signature: apiSignature
        });

        const access_token = response.data.accessToken;
        
        localStorage.setItem('access_token', access_token);
        
        this.accessToken = access_token;
        
        console.log(this.accessToken);
      } catch (error) {
        console.error('Error during login:', error);
      }
    },
    
    async fetchResults() {
      let token = localStorage.getItem('access_token');

      const params = new URLSearchParams({
        title_search: this.searchPhrase,
        views_weight_item_1: this.columns[0].viewsWeight,
        subscribers_weight_item_1: this.columns[0].subscribersWeight,
        perf_index_weight_item_1: this.columns[0].performanceWeight,
        uploaded_weight_item_1: this.columns[0].uploadedWeight,
        views_weight_item_2: this.columns[1].viewsWeight,
        subscribers_weight_item_2: this.columns[1].subscribersWeight,
        perf_index_weight_item_2: this.columns[1].performanceWeight,
        uploaded_weight_item_2: this.columns[1].uploadedWeight,
        views_weight_item_3: this.columns[2].viewsWeight,
        subscribers_weight_item_3: this.columns[2].subscribersWeight,
        perf_index_weight_item_3: this.columns[2].performanceWeight,
        uploaded_weight_item_3: this.columns[2].uploadedWeight,
        page:0,
        items_per_page: 50,
      }).toString();

      try {
        const response = await axios.get(`${this.api}?${params}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data && Array.isArray(data)) {
          this.columns.forEach((column, index) => {
            if (data[index] && Array.isArray(data[index].results)) {
              this.columns[index].results = data[index].results;
            }
          });
        }
      } catch (error) {
        console.error('Error fetching results:', error);
      }
    },
  },
};
</script>

<style>
.columns {
  display: flex;
}

.column {
  flex: 1;
  padding: 10px;
}
</style>
