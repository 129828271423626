<template>
  <div class="auth">
    <amplify-authenticator></amplify-authenticator>
  </div>
</template>

<script>
export default {
  name: "auth",
};
</script>

<style>
.auth {
  margin: 0 auto;
  width: 460px;
}
</style>