<template>
<amplify-authenticator>
    <div id="AWS_Quick_Sight_V2">
        <button @click="goBack">goBack</button>
        <br /> <br />
        <input type='text' v-model='ucid' size='40' placeholder='Enter UCID' />
        <button @click="FilerUCID(ucid)">Filter UCID</button>
        <br /> <br />

        <button @click="refresh_url()" style="display: none;">Refresh</button>
        <br /> <br />
        <iframe src="" ref="awsQSurl" id="awsQSurl" width="95%" height="1620" marginheight="0" marginwidth="0" frameborder="0"></iframe>
        <br /> <br />
        <br /> <br />
        <!-- <iframe src={{embeded_url}} width="95%" height="1620" marginheight="0" marginwidth="0" frameborder="1"> 
        </iframe>  -->
    </div>
    <!-- </div> -->
</amplify-authenticator>
</template>

<script>
// import {
//     API
// } from 'aws-amplify';


export default {
    name: 'AWS_Quick_Sight_V2',
    data() {
        return {
            embeded_url: null,
            ucid: "",
            dash_snapshot: ""
        };
    },
    mounted() {
        let that = this;        
        setInterval(() =>
        {
            if(that.dash_snapshot !== that.$props.dash_board_id)
            {
                that.$refs["awsQSurl"].src = "";
                that.dash_snapshot = that.$props.dash_board_id;
            }
        }, 250);        
    },
    props: ["dash_board_id"],
    methods: {
        blankIframe() {
            window.alert('hi');
        },
        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        },
        FilerUCID() {
            console.log(this.$props.dash_board_id);
            document.body.style.opacity = "0.5";
            this.refresh_url(this.$props.dash_board_id);

        },
        // async refresh_url() {
        //     const response = await API.get('pythonAPIQuickSight', '/qs/ucid'); //in the place of ucid have to pass real UCID
        //     console.log(response);
        //     return response;
        // },
        async refresh_url(dash_id) {            

            var apigClientFactory = require('aws-api-gateway-client').default;
            var config = {
                invokeUrl: /*'https://ijne7y54v1.execute-api.us-west-2.amazonaws.com/dev/'*/ "https://ijne7y54v1.execute-api.us-west-2.amazonaws.com/dev"
            }
            var apigClient = apigClientFactory.newClient(config);

            var pathParams = {
                // This is where any modeled request parameters should be added.
                // The key is the parameter name, as it is defined in the API in API Gateway.
                // param1: ''
                /*ucid: "UCkk2vs5EXLFUMuVyhy7oOMQ"*/
                ucid: this.ucid,
                dash_board_id: dash_id
            };

            var pathTemplate = ''; //'ql/{ucid}';
            var method = 'GET';
            var body = {
                // This is where you define the body of the request,
                ucid: this.ucid,
                dash_board_id: dash_id
            };

            var additionalParams = {
                // If there are any unmodeled query parameters or headers that must be
                //   sent with the request, add them here.
                headers: {
                    "Content-Type": "text/plain",
                    // "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
                    "accept": "*/*"
                },
                queryParams: {
                    ucid: this.ucid/*'UCkk2vs5EXLFUMuVyhy7oOMQ'*/,
                    dash_board_id: dash_id
                }
            };

            await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
                .then(function (result) {
                    // console.log(result['data']['body']);
                    document.getElementById("awsQSurl").src = result.data.AWSQuickSightUrl;
                    document.body.style.opacity = "1.0";
                    // Add success callback code here.
                }).catch(function (error) {
                    console.log(error);
                    // Add error callback code here.
                });
        }
    }
};
</script>
