import { createApp } from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import router from './router/router'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';


Amplify.configure(aws_exports);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

createApp(App)
  .use(router)
  .use(VueSidebarMenu)
  .mount('#app');
