<template>
  <amplify-authenticator>
    <div id="GoogleLooker">
      <button @click="goBack">goBack</button>
      <br />
      <br />
      <input v-model="ucid" size="40" placeholder="Enter UCID" />
      <button @click="FilerUCID(ucid)">Filter UCID</button>
      <!-- <p>Entered UCID is: {{ ucid }}</p> -->
      <br />
      <br />
      <button @click="GoogleLookerUrl()" style="display: none;">Refresh</button>
      <br />
      <br />
      <iframe
        src=""
        id="GoogleLookerUrl"
        width="95%"
        height="1620"
        marginheight="0"
        marginwidth="0"
        frameborder="0"
        ref="iframe_item"
      ></iframe>
      <br />
      <br />
    </div>
  </amplify-authenticator>
</template>

<script>
// import * as  apiGClient from  'apigClient.js';
import $ from "jquery";

export default {
  name: "GoogleLooker",
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    FilerUCID(ucid) {
      this.GoogleLookerUrl();
      console.log("entered UCID is:" + ucid);

    },
    // async GoogleLookerUrl() {
    //     const response = await API.get('GoogleLooker', ''); //in the place of ucid have to pass real UCID
    //     console.log(response);
    //     return response;
    // },
    async GoogleLookerUrl() {
      // testing UCID....
      /**************************
        UCkk2vs5EXLFUMuVyhy7oOMQ
        ************************
      */
      // set timeout is so that I can get access to jquery without vue saying it's undefined...

      setTimeout(() =>
      {
        $("body").css("opacity", "0.25");
        let iframe = $("iframe").first();
        iframe.off("load");
        iframe.on("load", (() =>
        {
          $("body").css("opacity", "1.0");
        }));
      }, 10);      

      let that = this;
      console.log(that);
      var apigClientFactory = require("aws-api-gateway-client").default;
      var config = {
        invokeUrl:
          /*https://v3o256fn85.execute-api.us-west-2.amazonaws.com/dev/*/
          "https://hzo74r4whh.execute-api.us-west-2.amazonaws.com/dev",
      };
      var apigClient = apigClientFactory.newClient(config);

      var pathParams = {
        // This is where any modeled request parameters should be added.
        // The key is the parameter name, as it is defined in the API in API Gateway.
        // param1: ''
        ucid: that.ucid
      };

      var pathTemplate = ""; //'ql/{ucid}';
      var method = "GET";
      var body = {
        // This is where you define the body of the request,
        ucid: that.ucid
      };

      var additionalParams = {
        // If there are any unmodeled query parameters or headers that must be
        //   sent with the request, add them here.
        headers: {
          "Content-Type": "text/plain",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          accept: "*/*",
        },
        queryParams: {
          ucid: that.ucid
        },
      };

      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function (result) {
          console.log(result);
          document.getElementById("GoogleLookerUrl").src = result.data.GoogleLookerUrl;         
          // Add success callback code here.
        })
        .catch(function (error) {
          console.log(error);
          // Add error callback code here.
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
