<template>
  <div id="investor-report" class="report-container">
    <div class="header">
      <strong>Please select the month to generate the investor report:</strong>
    </div>
    
    <div class="month-selection">
      <div v-for="month in months" :key="month" class="month-option">
        <input type="radio" v-model="selectedMonth" name="selectedMonth" :value="month" />
        <label :for="month">{{ month }} {{ month_avail_map.get(month) ? '' : ' - claims summary report not available yet' }}</label>
      </div>
    </div>

    <div class="preview-option">
      <input type="checkbox" v-model="isPreviewDailyRun" true-value="yes" false-value="no" id="previewDailyRun" />
      <label for="previewDailyRun">Preview Daily Run
        <span class="small-text">(claimed videos are retrieved from deals_video_metadata data will be pulled from 1st day of last month to today on daily level regardless of the selected month)</span>
      </label>
    </div>

    <button @click="submit()" class="submit-button">Submit</button>

    <ul class="report-info">
      <li>
        It takes about 45 mins to generate the report. Then you can access the report from
        <a href="https://s3.console.aws.amazon.com/s3/buckets/spotter-data-ops?region=us-west-2&prefix=investor-reporting/final-investor-report/&showversions=false">
          here</a>.
      </li>
    </ul>
  </div>
</template>


<script>
import { API } from "aws-amplify";
import "@/assets/css/investor.css";

export default {
  name: "Investor-report",

  data() {
    return {
      message: "",
      month_avail: [],
      months: [],
      month_avail_map: new Map(),
    };
  },

  created() {
    this.months = [];
    this.isTestRun = "no";
    this.isPreviewDailyRun = "no";
    this.availability();
  },

  methods: {
    async availability() {
      console.log("Get investor report availability");

      const apiName = "investorReport";
      const path = "/availability";
      const myInit = {
        headers: {
          "Content-Type": "text/plain",
          accept: "*/*",
        },
        response: true,
        queryStringParameters: {
          path: "availability",
        },
      };

      await API.get(apiName, path, myInit)
        .then((response) => {
          this.month_avail = response.data;
          this.month_avail.sort(function (a, b) {
            return a.month > b.month ? 1 : b.month > a.month ? -1 : 0;
          });
          for (var i = 0; i < this.month_avail.length; i++) {
            var month = this.month_avail[i]["month"];
            var is_available = this.month_avail[i]["is_available"];
            this.months.push(month);
            this.month_avail_map.set(month, is_available);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },

    async submit() {
      if (confirm("Are you sure to process the investor report?")) {
        console.log("Selected month is:" + this.selectedMonth);
        console.log("isTestRun is:" + this.isTestRun);
        console.log("isPreviewDailyRun is:" + this.isPreviewDailyRun);

        const apiName = "investorReport";
        const path = "/generate";
        const myInit = {
          headers: {
            "Content-Type": "text/plain",
            accept: "*/*",
          },
          response: true,
          queryStringParameters: {
            path: "generate",
            selectedMonth: this.selectedMonth,
            isTestRun: this.isTestRun,
            isPreviewRun: "no",
            isPreviewDailyRun: this.isPreviewDailyRun,
          },
        };

        await API.get(apiName, path, myInit)
          .then((response) => {
            console.log(response);
            this.message = response.data;
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            this.message = error.response.data;
          });
      }
    },
  },
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
</style>