<template>
  <div id="Tools">
    <sidebar-menu :menu="menu" @item-click="onItemClick" />

    <component v-bind:is="selectedMenu"></component>
  </div>
</template>

<script>
import repricing from "./repricing.vue";
import investor from "./investor.vue";
import cmsdatapull from "./cmsdatapull.vue";
import titlesearch from "./titlesearch.vue";
import generatebio from "./generate_bio.vue";
import news from "./news.vue";
import askde from "./askde.vue";


export default {
  name: "Tools",

  components: {
    repricing,
    investor,
    cmsdatapull,
    titlesearch,
    generatebio,
    news,
    askde
  },

  data() {
    return {
      selectedMenu: "repricing",

      menu: [
        {
          href: "#repricing",
          title: "Repricing",
          icon: "fa fa-user",
          componentName: "repricing",
        },
        {
          href: "#investor",
          title: "Investor Report",
          icon: "fa fa-user",
          componentName: "investor",
        },
        {
          href: "#cmsdatapull",
          title: "CMS Data Pull",
          icon: "fa fa-user",
          componentName: "cmsdatapull",
        },
        {
          href: "#titlesearch",
          title: "Title Search",
          icon: "fa fa-user",
          componentName: "titlesearch",
        },
        {
          href: "#generatebio",
          title: "Generate Bio",
          icon: "fa fa-user",
          componentName: "generatebio",
        },
        {
          href: "#news",
          title: "News",
          icon: "fa fa-user",
          componentName: "news",
        },
        {
          href: "#askde",
          title: "Ask DE",
          icon: "fa fa-user",
          componentName: "askde",
        },
      ],
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    onItemClick(event, item) {
      this.selectedMenu = item.componentName;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
