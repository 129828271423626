<template>
  <div class="chat-container">
    <div class="input-area">
      <input v-model="userInput" @keyup.enter="sendMessage" placeholder="Type a message..." />
      <button @click="sendMessage">Send</button>
    </div>
    <div class="messages">
      <div v-for="(msg, index) in messages" :key="index" class="message" :class="{'user': msg.type === 'user', 'bot': msg.type === 'bot'}">
        <p>{{ msg.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';

export default {
  name: 'ChatBot',
  setup() {
    // DEV
    //const api = "/api/confluence"

    // SERVER, point to DEV EKS
    const api = 'https://de-web-app.de.dev.internal.spotter.la/confluence';
    const userInput = ref('');
    const messages = ref([]);

    const sendMessage = async () => {
      try {
        const params = new URLSearchParams({
          q: userInput.value.trim(),
        }).toString();

        const response = await axios.get(`${api}?${params}`);
        const data = response.data;
        
        messages.value.push({
          type: 'user',
          text: userInput.value.trim(),
        });

        messages.value.push({
          type: 'bot',
          text: JSON.stringify(data),
        });
      } catch (error) {
        console.error("Failed to fetch bot response:", error);
        messages.value.push({
          type: 'bot',
          text: "Sorry, I'm having trouble understanding you right now.",
        });
      }
    };

    return {
      userInput,
      messages,
      sendMessage,
    };
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column-reverse; /* Change the order to ensure the input area stays at the bottom */
  height: 100vh;
  justify-content: flex-end; /* Adjust alignment */
  background-color: #f0f2f5; /* Light background for the chat container */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Improved typography */
}

.input-area {
  display: flex;
  padding: 12px; /* More spacing around the input area */
  background-color: white; /* Distinguish the input area with a different background */
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.input-area input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 8px; /* Add some space between input and button */
  border: 1px solid #ccc; /* Lighter border for the input */
  border-radius: 20px; /* Rounded corners for the input field */
  outline: none; /* Remove outline on focus */
}

.input-area button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff; /* Button color */
  border: none; /* Remove border */
  border-radius: 20px; /* Rounded corners for the button */
  color: white; /* Text color for the button */
  font-weight: bold; /* Make the button text a bit bolder */
  transition: background-color 0.2s; /* Smooth background color transition */
}

.input-area button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.messages {
  overflow-y: auto;
  padding: 20px; /* More space around messages */
}

.message {
  margin: 10px 0; /* Adjust spacing */
  padding: 12px; /* More padding inside messages */
  border-radius: 20px; /* More pronounced rounded corners for messages */
  max-width: 75%; /* Limit message width */
}

.user {
  background-color: #007bff;
  align-self: flex-end;
  color: white; /* Ensure text color is white for user messages */
}

.bot {
  background-color: #e0e0e0; /* Lighter background for bot messages */
  color: #333; /* Darker text color for better contrast */
  align-self: flex-start;
}
</style>

