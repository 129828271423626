<template>
  <amplify-authenticator>
    <div id="demo_poc">
      <button @click="goBack">goBack</button>
      <br />
      <br />
      <input v-model="ucid" size="40" placeholder="Enter UCID" />
      <button @click="FilerUCID(ucid)">Filter UCID</button>
      <!-- <p>Entered UCID is: {{ ucid }}</p> -->
      <br />
      <br />
      <button @click="DomoInnerHtml()">Refresh</button>
      <br />
      <br />
      <iframe
        srcdoc=""
        id="DomoInnerHtml"
        width="95%"
        height="1620"
        marginheight="0"
        marginwidth="0"
        frameborder="0"
      ></iframe>
      <br />
      <br />
    </div>
  </amplify-authenticator>
</template>

<script>
export default {
  name: "domo_poc",
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    FilerUCID(ucid) {
      console.log("entered UCID is:" + ucid);
    },
    onSubmit() {
        document.getElementById("myForm").submit();
        console.log("form submitted");
    },
    async DomoInnerHtml() {
      var apigClientFactory = require("aws-api-gateway-client").default;
      var config = {
        invokeUrl:
          "https://nzlcx1a7ye.execute-api.us-west-2.amazonaws.com/dev/",
      };
      var apigClient = apigClientFactory.newClient(config);

      var pathParams = {
        // This is where any modeled request parameters should be added.
        // The key is the parameter name, as it is defined in the API in API Gateway.
        // param1: ''
        ucid: "UCkk2vs5EXLFUMuVyhy7oOMQ",
      };

      var pathTemplate = ""; //'ql/{ucid}';
      var method = "GET";
      var body = {
        // This is where you define the body of the request,
        ucid: "some_UCID",
      };

      var additionalParams = {
        // If there are any unmodeled query parameters or headers that must be
        //   sent with the request, add them here.
        headers: {
          "Content-Type": "text/plain",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          accept: "*/*",
        },
        queryParams: {
          ucid: "UCkk2vs5EXLFUMuVyhy7oOMQ",
        },
      };

      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function (result) {
          console.log(result["data"]);
          // console.log(JSON.parse(result['data']));
          var embed_id = result["data"]["embed_id"];
          var authentication = result["data"]["authentication"];
          var Inner_html =
            '<html> <body> <form id="myForm" @onLoad="onSubmit()" action="https://public.domo.com/embed/pages/' +
            embed_id +
            '" method = "post" >' +
            '<input type="hidden" name="embedToken" value="' +
            authentication +
            '" >  </body> </html>';
          console.log(Inner_html);
          document.getElementById(
            "DomoInnerHtml"
          ).contentDocument.body.innerHTML = Inner_html;
          document.getElementById("DomoInnerHtml").contentDocument.getElementById("myForm").submit(); // to submit the inner HTML form.
          // Add success callback code here.
        })
        .catch(function (error) {
          console.log(error);
          // Add error callback code here.
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
