<template>
  <div id="repricing" class="container">
    <!-- Note Section -->
    <div class="note-section">
      <strong>Note:</strong>
      <ul>
        <li>Success notification message will be sent to the pricing-analytics-tech Slack channel upon completion of the repricing job.</li>
        <li>It is recommended to limit the batch size to 10 channels or fewer to avoid exceeding the per-minute quota.</li>
        <li>Backfill retrieves the full historical data for the specified UCIDs</li>
        <li>Incremental retrieves only recent data from the past few months for video daily, video monthly, and traffic.</li>
        <li style="color: red;">Only perform backfill when absolutely necessary. Due to current data pipeline limitations, channels must be processed individually in backfill mode, whereas incremental mode allows for batch processing of channels.</li>
      </ul>
    </div>

    <!-- Textarea for UCIDs -->
    <textarea
      id="channelIds"
      v-model="channelIds"
      placeholder="Enter UCID(s) for repricing. Please start a new line for each UCID."
      rows="20"
      cols="30"
      class="ucid-textarea"
    ></textarea>
    
    <!-- Action Selection -->
    <div class="action-selection">
      <label><strong>Please select the action:</strong></label>
      <div v-for="action in actions" :key="action" class="radio-group">
        <input
          type="radio"
          v-model="selectedAction"
          :value="action"
          :id="action"
          name="selectedAction"
          class="radio-input"
        />
        <label :for="action">{{ action }}</label>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="submit-button">
      <button @click="repricing()">Submit</button>
    </div>

    <!-- Message Section -->
    <div class="message-section">
      <p v-if="message">{{ message }}</p>
    </div>
  </div>
</template>



<script>
import { API } from "aws-amplify";
import "@/assets/css/repricing.css";

export default {
  name: "Repricing",

  data() {
    return {
      message: "",
      actions: [],
    };
  },

  created() {
    this.actions = ["backfill", "incremental"];
    this.selectedAction = "incremental";
  },

  methods: {
    async repricing() {
      console.log("Selected action is:" + this.selectedAction);
      console.log("Entered UCID is:" + this.channelIds);
      var array = this.channelIds.split("\n");
      array = array.filter(function (line) {
        return line.length == 24 && line.startsWith("UC");
      });
      const ucids = array.join(",").trim();

      const apiName = "repricing";
      const path = "/repricing";
      const myInit = {
        headers: {
          "Content-Type": "text/plain",
          accept: "*/*",
        },
        response: true,
        queryStringParameters: {
          action: this.selectedAction,
          ucid: ucids,
        },
      };

      await API.get(apiName, path, myInit)
        .then((response) => {
          console.log(response);
          this.message = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.message = error.response.data;
        });
    },
  },
};
</script>