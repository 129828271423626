/* eslint-disable vue/no-parsing-error */
/* eslint-disable vue/no-deprecated-slot-attribute */
<template>
  <!-- <div>
    <img alt="Vue logo" src="./assets/logo.png" />
  </div> -->

  <amplify-authenticator>
    <!-- The rest of your app code -->
    <div id="app">
      <HelloWorld msg="Welcome to Spotter!" />
      <div id="nav">
        <router-link to="/tools">Tools</router-link>
        <!-- <router-link to="/domo_poc">Domo POC</router-link> |
        <router-link to="/GoogleLooker">Google Looker</router-link> |
        <router-link
          :to="{
            name: 'AWS_Quick_Sight_V2',
            params: { dash_board_id: 'c6e68e6d-64a9-4116-8e3b-2d3910ad4f28' },
          }"
          >AWS Quick Sight</router-link
        >
        |
        <router-link
          :to="{
            name: 'AWS_Quick_Sight_V2',
            params: { dash_board_id: '72b4aa4f-9a1b-45bb-9caf-55c38afe1d7d' },
          }"
          >AWS Quick Sight_V2</router-link
        > -->
      </div>
      <br />
      <router-view> </router-view>
    </div>
    <br />
    <amplify-sign-out v-if="signedIn"></amplify-sign-out>
  </amplify-authenticator>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import { Auth, Hub } from "aws-amplify";

export default {
  name: "app",
  components: {
    HelloWorld,
  },
  data() {
    return {
      signedIn: false,
    };
  },
  beforeCreate() {
    Hub.listen("auth", (data) => {
      console.log("data:", data);
      const { payload } = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
        this.$router.push("/tools");
      }
      if (payload.event === "signOut") {
        this.$router.push("/auth");
        this.signedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch(() => (this.signedIn = false));
  },
  methods: {
    loadHome: function () {},
  },
  mounted: function () {
    //forcing the loadHome to run when no links selected on the page
    // this.loadHome();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: left;
  width: 25%;
}

table.center {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  width: 50%;
}
</style>
